import React, { ReactNode, useState } from "react";
import config from "../constants";

const initialState= {
  isLoading: false,
  setIsLoading: (active: boolean) => {},
  step: "0",
  setStep: (step: string) => {},
  error: null,
  setError: (error: any) => {},
  token: "",
  setToken: (token: string) => {},
  recipients:  [""],
  setRecipients: (recipients: string[]) => {},
}

export const SendStoreContext = React.createContext(initialState);

const SendStoreProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState("recipients");
  const [error, setError] = useState(null);
  const [token, setToken] = useState(config.multisendAddress);
  const [recipients, setRecipients] = useState<string[]>([]);



  const store = {
    isLoading,
    setIsLoading,
    step,
    setStep,
    error,
    setError,
    token,
    setToken,
    recipients,
    setRecipients,
  };

  return (
    <SendStoreContext.Provider value={store}>
      {children}
    </SendStoreContext.Provider>
  );
};

export default SendStoreProvider;
