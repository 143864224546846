import { ethers } from "ethers";

const PRECISION = 3;

export const weiToEth = (value: string, decimals = 18) => {
  const displayValue =
    ethers.BigNumber.from(value)
      .div(ethers.BigNumber.from(10).pow(decimals - PRECISION))
      .toNumber() / ethers.BigNumber.from(10).pow(PRECISION).toNumber();
  return formatNumber(displayValue);
};

export const ethToWei = (value: string, decimals = 18) => {
  const decimalCount =
    value.indexOf(".") < 0 ? 0 : value.length - 1 - value.indexOf(".");
  const numberOfTokens = ethers.BigNumber.from(value.replace(".", "") || "0")
    .mul(ethers.BigNumber.from(10).pow(decimals - decimalCount))
    .toString();

  return numberOfTokens;
};

export const formatNumber = (num: number) => {
  return new Intl.NumberFormat("en-US").format(num);
};
