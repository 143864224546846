import React from "react";
import { useMetaMask } from "metamask-react";
import Button from "react-bootstrap/Button";

const Connect = () => {
  const { connect } = useMetaMask();
  return (
    <Button onClick={connect} variant="success" size="lg" block>
      Connect to MetaMask
    </Button>
  );
};

export default Connect;
