import React from "react";
import { useMetaMask } from "metamask-react";
import Spinner from "react-bootstrap/Spinner";

const Account = () => {
  const { status, account } = useMetaMask();

  const statusText = status[0].toUpperCase() + status.slice(1);

  if (status !== "connected") {
    return (
      <div className="connection">
        <Spinner
          className="statusIcon"
          role="span"
          size="sm"
          animation="grow"
          variant="lig"
        />
        <div className="statusText">{statusText}...</div>
      </div>
    );
  }

  return (
    <div className="connection">
      <span className="statusIcon success"></span>
      <div className="statusText">
        {statusText}: {account}
      </div>
    </div>
  );
};

export default Account;
