import React from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";

import logo from "../assets/images/logo.svg";

const Header = () => {

  return (
    <Navbar fixed="top" bg="dark" variant="dark">
      <Link to={"/"}>
        <Navbar.Brand>
          <img
            alt="Taraxa Multisend"
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Taraxa Multisend
        </Navbar.Brand>
      </Link>
    </Navbar>
  );
};

export default Header;
