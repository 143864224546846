import React from "react";
import { MetaMaskProvider } from "metamask-react";
import Container from "react-bootstrap/Container";
import Send from "../components/Send";
import SendStoreProvider from "../store/Send";

const Home = () => (
  <Container fluid="sm">
    <MetaMaskProvider>
      <SendStoreProvider>
        <Send />
      </SendStoreProvider>
    </MetaMaskProvider>
  </Container>
);

export default Home;
