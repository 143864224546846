import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { weiToEth } from "../../utils";
import { SendStoreContext } from "../../store/Send";
import { BigNumber, ethers } from "ethers";
import { useMetaMask } from "metamask-react";
import useChain from "../../services/useChain";
import { Alert } from "react-bootstrap";

const Confirmation = () => {
  const { provider } = useChain();
  const { account } = useMetaMask();
  const { setStep, recipients } = React.useContext(SendStoreContext);
  const [balance, setBalance] = useState<BigNumber>(BigNumber.from("0"));


  useEffect(() => {
    const fetchBalance = async () => {
      if (account && provider) {
        const balance = await provider.getBalance(account);
        setBalance(balance);
      }
    };
    fetchBalance();
  }, [account]);
  
  const total = recipients.reduce(
    (acc, curr) => acc.add(ethers.BigNumber.from(curr[1])),
    ethers.BigNumber.from("0")
  );

  const hasEnoughBalance = balance.gte(total);
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Recipient</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {recipients.map((recipient, id) => {
            return (
              <tr key={id}>
                <td>{recipient[0]}</td>
                <td>{weiToEth(recipient[1])}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {!hasEnoughBalance && (
        <Alert variant="danger">
          You do not have enough balance for the full amount of {" "}
          <strong>
              {weiToEth(total.toString(), 18)
              }{" "} TARA
          </strong>{" "}
        </Alert>
      )}
      <Button
        variant="success"
        block={true}
        size="lg"
        type="submit"
        disabled={!hasEnoughBalance}
        onClick={(ev) => {
          ev.preventDefault();
          setStep("send");
        }}
      >
        Confirm
      </Button>
    </>
  );
};

export default Confirmation;
