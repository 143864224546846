import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import parseCsv from "csv-parse/lib/sync";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { ethToWei } from "../../utils";
import { SendStoreContext } from "../../store/Send";

const EditForm = () => {
  const { setStep, token, recipients, setRecipients } =
    React.useContext(SendStoreContext);
  const [addresses, setAddresses] = useState<string>("");
  const [file, setFile] = useState<string>("");

  useEffect(() => {
    let rcp;
    for (const delimiter of [";", ","]) {
      rcp = parseCsv(addresses, {
        delimiter,
        cast: false,
        castDate: false,
        trim: true,
        skipEmptyLines: true,
        skipLinesWithEmptyValues: true,
        skipLinesWithError: true,
      });
      rcp = rcp.filter((line: any) => line.length === 2);
      rcp = rcp.filter((line: any) => ethers.utils.isAddress(line[0]));
      if (rcp.length > 0) {
        setRecipients(
          rcp.map((r: any) => {
            return [r[0], ethToWei(r[1], 18)];
          })
        );
        break;
      }
    }
  }, [token, addresses]);

  return (
    <Form>
      <Form.Group controlId="addresses">
        <Form.Label>List of Addresses in CSV format</Form.Label>
        <Form.Control
          isInvalid={recipients.length === 0}
          isValid={recipients.length > 0}
          size="lg"
          as="textarea"
          rows={10}
          onChange={(ev) => {
            setAddresses(ev.target.value);
          }}
          value={addresses}
        />
        <Form.Text className="text-muted">
          {recipients.length} valid recipients
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="csv">
        <Form.File
          custom
          label={file ? file : "Upload CSV file"}
          onChange={(ev: any) => {
            if (!ev.target.files[0]) {
              return;
            }

            const file = ev.target.files[0];
            const fileReader = new FileReader();

            fileReader.readAsText(file);

            fileReader.onload = () => {
              setFile(file.name);
              setAddresses(String(fileReader.result));
            };

            fileReader.onerror = () => {
              console.log(fileReader.error);
            };
          }}
        />
      </Form.Group>
      <Button
        disabled={recipients.length === 0}
        variant="success"
        block={true}
        size="lg"
        type="submit"
        onClick={(ev) => {
          ev.preventDefault();
          setStep("confirmation");
        }}
      >
        Next
      </Button>
    </Form>
  );
};

export default EditForm;
