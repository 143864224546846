import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { weiToEth } from "../../utils";
import useMultisend from "../../services/useMultisend";
import { SendStoreContext } from "../../store/Send";
import { ethers } from "ethers";

const SendTokens = () => {
  const { setError, recipients, setStep } = React.useContext(SendStoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setSent] = useState(false);
  const multisend = useMultisend();

  const sendTokens = async () => {
    if(!multisend){
      setError("Multisend contract undefined!");
      return;
    }
    setError(null);
    setIsLoading(true);

    const addresses = recipients.map((recipient) => recipient[0]);
    const amounts = recipients.map((recipient) => recipient[1]);
    const total = amounts.reduce(
      (acc, curr) => acc.add(ethers.BigNumber.from(curr)),
      ethers.BigNumber.from("0")
    );
    try {
      const mutisendTx = await multisend.multisendToken(
        addresses,
        amounts,
        {
          gasLimit: 6000000,
          value: total
        }
      );
      await mutisendTx.wait(1);
      setSent(true);
    } catch (e: any) {
      setError(e.message ? e?.message : e);
      setSent(false);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Alert variant="warning">Send tokens to the following addresses?</Alert>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Recipient</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {recipients.map((recipient) => (
            <tr key={recipient[0]}>
              <td>{recipient[0]}</td>
              <td>
                  {weiToEth(recipient[1], 18)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        variant="success"
        block={true}
        disabled={isLoading || isSent}
        size="lg"
        type="submit"
        onClick={(ev) => {
          ev.preventDefault();
          sendTokens();
        }}
      >
        {isLoading ? "Sending..." : isSent ? "Tokens Sent!" : "Send"}
      </Button>
      {isSent && <Button
        variant="outline-dark"
        block={true}
        size="lg"
        type="submit"
        onClick={(ev) => {
          ev.preventDefault();
          setStep("recipients");
        }}
      >
       Back
      </Button>}
    </>
  );
};

export default SendTokens;
