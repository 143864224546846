import React from "react";
import { useMetaMask } from "metamask-react";
import Alert from "react-bootstrap/Alert";
import NotAvailable from "./Send/NotAvailable";
import Connect from "./Send/Connect";
import Account from "./Send/Account";
import TokenInfo from "./Send/TokenInfo";
import EditForm from "./Send/EditForm";
import Confirmation from "./Send/Confirmation";
import SendTokens from "./Send/SendTokens";
import { SendStoreContext } from "../store/Send";

const Send = () => {
  const { status } = useMetaMask();
  const { step, error } = React.useContext(SendStoreContext);

  let contents = (
    <>
      <Account />
      <TokenInfo />
      {error && (
        <Alert
          variant="danger"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {error}
        </Alert>
      )}
      {step === "recipients" && <EditForm />}
      {step === "confirmation" && <Confirmation />}
      {step === "send" && <SendTokens />}
    </>
  );

  if (status === "unavailable") {
    contents = <NotAvailable />;
  }
  if (status === "notConnected") {
    contents = <Connect />;
  }

  return <div className="send">{contents}</div>;
};

export default Send;
