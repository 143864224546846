import React, { useState, useEffect } from "react";
import { BigNumber } from "ethers";
import { useMetaMask } from "metamask-react";
import Form from "react-bootstrap/Form";
import { useIndexer } from "../../services/useIndexer";
import useChain from "../../services/useChain";

const TokenInfo = () => {
  const { account } = useMetaMask();
  const { provider } = useChain();
  const { fetchTotalSupply } = useIndexer();
  const [totalSupply, setTotalSupply] = useState<string>("0");
  const [balance, setBalance] = useState<BigNumber>(BigNumber.from("0"));

  useEffect(() => {
    const fetchInitialData = async () => {
      const supply = await fetchTotalSupply();
      setTotalSupply(supply);
      if (account && provider) {
        const balance = await provider.getBalance(account);
        setBalance(balance);
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchBalance = async () => {
      if (account && provider) {
        const balance = await provider.getBalance(account);
        setBalance(balance);
      }
    };
    fetchBalance();
  }, [account]);

  return (
    <Form>
      <Form.Group controlId="token">
        <Form.Text className="text-muted">
          <>
            Token details:
            <ul>
              <li>Name: TARAXA Token</li>
              <li>Symbol: TARA</li>
              <li>Decimals: 18</li>
              <li>Total Supply: {totalSupply}</li>
              <li>
                Balance: {balance.div(BigNumber.from(10).pow(18)).toString()}
              </li>
            </ul>
          </>
        </Form.Text>
      </Form.Group>
    </Form>
  );
};

export default TokenInfo;
