import React from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

const Footer = () => {

  return (
    <Navbar bg="dark" variant="dark" fixed="bottom" expand="lg">
      <Link to={"/"}>
        <Navbar.Brand>Taraxa Multisend</Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="footer-nav" />
      <Navbar.Collapse id="footer-nav">
        <Nav className="ml-auto">
          <Nav.Link target="_blank" href="https://www.taraxa.io/">
            Taraxa
          </Nav.Link>
          <Nav.Link target="_blank" href="https://explorer.testnet.taraxa.io/">
            Explorer
          </Nav.Link>
          <Nav.Link target="_blank" href="https://sandbox.testnet.taraxa.io/">
            Sandbox
          </Nav.Link>
          <Nav.Link
            target="_blank"
            href="https://docs.taraxa.io/tech-whitepaper/abstract"
          >
            Whitepaper
          </Nav.Link>
          <Nav.Link target="_blank" href="https://github.com/taraxa-project/">
            Github
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Footer;
