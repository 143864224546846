import React from "react";
import { BigNumber } from "ethers";
import config from "../constants";

export const useIndexer = () => {
  const fetchTotalSupply = async () => {
    const totalSupplyUrl = `${config.mainnetIndexerUrl}/totalSupply`;
    let totalSupply = BigNumber.from(0);

    try {
      const headers = {
        "Content-Type": "application/json",
        "Accept-Encoding": "gzip,deflate,compress",
      };
      const response = await fetch(totalSupplyUrl, { headers });
      const body = await response.json();
      totalSupply = BigNumber.from(body);
    } catch (error) {
      console.error(error);
    }

    return totalSupply.div(BigNumber.from(10).pow(18)).toString();
  };
  return { fetchTotalSupply };
};
