import { ethers } from "ethers";
import { useMemo } from "react";
import constants from "../constants";
import useChain from "./useChain";

function useMultisend() {
  const { chainId, provider, signer } = useChain();
  const abi = ["function multisendToken(address[],uint[]) payable external"];
  const instance = useMemo(() => {
    if (provider && signer) {
      const contract = new ethers.Contract(
        constants.multisendAddress,
        abi,
        provider
      );
      return contract.connect(signer);
    }
  }, [chainId, signer]);
  return instance;
}

export default useMultisend;
